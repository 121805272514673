import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function Feature({ data }) {
  return (
    <section className="service-iam-features">
      <div className="container">
        <div className="service-iam-features-content">
          <div className="service-iam-features-content-image">
            <img src={data.primary.icon1.url} alt="sso" />
          </div>
          <RichText render={data.primary.description_one} />
          <RichText render={data.primary.description_two} />
        </div>
      </div>
    </section>
  );
}
